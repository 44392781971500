@tailwind base;
@tailwind components;
@tailwind utilities;


h1 {
  font-family: 'futura';
  src:"../public/futura";
}

h2 {
  font-family: 'futura';
  src:"../public/futura";
}

p {
  font-family: 'futura';
  src:"../public/futura";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-prev {
  left:-7% !important;
  z-index: 1;
}
.slick-next {
  right: -7% !important;
  z-index: 1;
}

.slick-arrow.slick-next,
.slick-arrow.slick-prev{
background-color: rgb(180, 180, 180);
width: 25px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
opacity: 0.3;
height:50px;
width:50px;
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover
{
    background-color: rgb(180, 180, 180);
    opacity:1;
}

.slick-arrow.slick-disabled {
  display: none;
}

.slick-prev::before,.slick-next::before{
   display:none;
    }

    .highlight-card {
      transition: transform 0.3s cubic-bezier(0, 0, 0.5, 1);
    }
    
    .highlight-card:hover {
      transform: scale(1.02);
    }


